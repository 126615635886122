import SvgBellSnooze from "@ch/ui/pro/icon/icons/bell-snooze";
import * as React from "react";

import { IconProps, DISABLED_COLOR } from "./";

export default function BellSnooze(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;

  return <SvgBellSnooze {...props} color={color} />;
}
