import SvgAlarmClock from "@ch/ui/pro/icon/icons/alarm-clock";
import * as React from "react";

import { IconProps, DISABLED_COLOR } from "./";

export default function AlarmClock(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;

  return <SvgAlarmClock {...props} color={color} />;
}
