import * as React from "react";
import Svg, {Path} from "react-native-svg";
import {SingleIconProps} from "../";

const SvgBellSnooze = (props: SingleIconProps) => (
  <Svg
    width={props.size}
    height={props.size}
    viewBox="0 0 24 24"
    fill="none"
    title={props.accessibilityLabel}
    style={props.style}
  >
    <Path
      d="M12 1.25C12.4142 1.25 12.75 1.58579 12.75 2V3.29126C13.6975 3.39612 14.587 3.69731 15.3756 4.15349C15.7341 4.3609 15.8566 4.81969 15.6492 5.17824C15.4418 5.53678 14.983 5.65931 14.6245 5.4519C13.853 5.00564 12.9575 4.75 12 4.75C9.10053 4.75 6.75002 7.1005 6.75002 10C6.75002 13.6708 6.22127 15.8614 5.47147 17.25H18.5289C18.3711 16.9577 18.2225 16.6291 18.0866 16.2578C17.9442 15.8689 18.1441 15.4381 18.533 15.2957C18.922 15.1533 19.3528 15.3532 19.4952 15.7422C19.8109 16.6045 20.1839 17.1232 20.5304 17.4697C20.7449 17.6842 20.809 18.0068 20.6929 18.287C20.5768 18.5673 20.3034 18.75 20 18.75H4.00002C3.69668 18.75 3.4232 18.5673 3.30711 18.287C3.19103 18.0068 3.25519 17.6842 3.46969 17.4697C4.26115 16.6782 5.25002 14.9239 5.25002 10C5.25002 6.52559 7.87505 3.66429 11.25 3.2912V2C11.25 1.58579 11.5858 1.25 12 1.25Z"
      fill={props.color}
    />
    <Path
      d="M10.8112 20.3862C10.6832 19.9923 10.26 19.7767 9.8661 19.9047C9.47216 20.0327 9.25657 20.4558 9.38457 20.8498C9.56403 21.4021 9.91373 21.8834 10.3836 22.2248C10.8534 22.5661 11.4192 22.75 12 22.75C12.5807 22.75 13.1466 22.5661 13.6164 22.2248C14.0862 21.8834 14.4359 21.4021 14.6154 20.8498C14.7434 20.4558 14.5278 20.0327 14.1338 19.9047C13.7399 19.7767 13.3168 19.9923 13.1888 20.3862C13.1072 20.6373 12.9483 20.8561 12.7347 21.0112C12.5211 21.1664 12.2639 21.25 12 21.25C11.736 21.25 11.4788 21.1664 11.2652 21.0112C11.0517 20.8561 10.8927 20.6373 10.8112 20.3862Z"
      fill={props.color}
    />
    <Path
      d="M14 9.75C13.5858 9.75 13.25 9.41421 13.25 9C13.25 8.58579 13.5858 8.25 14 8.25H18C18.3034 8.25 18.5768 8.43273 18.6929 8.71299C18.809 8.99324 18.7449 9.31583 18.5304 9.53033L15.8107 12.25H18C18.4142 12.25 18.75 12.5858 18.75 13C18.75 13.4142 18.4142 13.75 18 13.75H14C13.6967 13.75 13.4232 13.5673 13.3071 13.287C13.191 13.0068 13.2552 12.6842 13.4697 12.4697L16.1894 9.75H14Z"
      fill={props.color}
    />
    <Path
      d="M17.25 3C17.25 3.41421 17.5858 3.75 18 3.75H19.1894L17.4697 5.46967C17.2552 5.68417 17.191 6.00676 17.3071 6.28701C17.4232 6.56727 17.6967 6.75 18 6.75H21C21.4142 6.75 21.75 6.41421 21.75 6C21.75 5.58579 21.4142 5.25 21 5.25H19.8107L21.5304 3.53033C21.7449 3.31583 21.809 2.99324 21.6929 2.71299C21.5768 2.43273 21.3034 2.25 21 2.25H18C17.5858 2.25 17.25 2.58579 17.25 3Z"
      fill={props.color}
    />
  </Svg>
);

export default SvgBellSnooze;
