import React from "react";
import { Color } from "react-native-svg";

import colors from "../colors.json";

import AlarmClock from "./AlarmClock";
import BellSnooze from "./BellSnooze";
// TODO: Add this in foundation.
import Calendar from "./Calendar";
import Carbon from "./Carbon";
import Check from "./Check";
import CheckmarkInCircleFilled from "./CheckmarkInCircleFilled";
import ChevronDown from "./ChevronDown";
import ChevronLeft from "./ChevronLeft";
import ChevronRight from "./ChevronRight";
import ChevronUp from "./ChevronUp";
import ChevronUpDown from "./ChevronUpDown";
import CircledExclamationPoint from "./CircledExclamationPointIcon";
import Clipboard from "./Clipboard";
import Close from "./Close";
import Dashboard from "./Dashboard";
import Document from "./Document";
import DownTriangleSmall from "./DownTriangleSmall";
import EnrolledMember from "./EnrolledMember";
import ExclamationInTriangleFilled from "./ExclamationInTriangleFilled";
import EyeClosed from "./EyeClosed";
import EyeOpen from "./EyeOpen";
import Failure from "./Failure";
import FileDollar from "./FileDollar";
import Group from "./Group";
import GroupOfThree from "./GroupOfThree";
import Image from "./Image";
import Info from "./Info";
import KeyboardReturnKey from "./KeyboardReturnKey";
import LeftArrow from "./LeftArrow";
import Lock from "./Lock";
import Members from "./Members";
import No from "./No";
import Organization from "./Organization";
import People from "./People";
import Person from "./Person";
import Pin from "./Pin";
import Report from "./Report";
import RightTriangleSmall from "./RightTriangleSmall";
import Search from "./Search";
import Settings from "./Settings";
import Shot from "./Shot";
import SignOut from "./SignOut";
import Success from "./Success";
// TODO: Add this in foundation.
import Trashcan from "./Trashcan";

export const DISABLED_COLOR = colors.gray[200];

export type IconName =
  | "alarm-clock"
  | "bell-snooze"
  // TODO: Add this in foundation.
  | "calendar"
  // TODO: Add this in foundation.
  | "trashcan"
  // TODO: add this in foundation
  | "circled-exclamation-point"
  // TODO: add this in foundation
  | "exclamation-in-triangle-filled"
  // TODO: add this in foundation
  | "checkmark-in-circle-filled"
  | "carbon"
  | "check"
  | "chevron-up"
  | "chevron-up-down"
  | "chevron-down"
  | "chevron-left"
  | "chevron-right"
  | "clipboard"
  | "close"
  | "dashboard"
  | "document"
  | "down-triangle-small"
  | "right-triangle-small"
  | "enrolled-member"
  | "eye-closed"
  | "eye-open"
  | "failure"
  | "file-dollar"
  | "group"
  | "group-of-three"
  | "image"
  | "info"
  | "keyboard-return-key"
  | "left-arrow"
  | "lock"
  | "members"
  | "no"
  | "organization"
  | "people"
  | "person"
  | "pin"
  | "report"
  | "search"
  | "settings"
  | "shot"
  | "sign-out"
  | "success";

export const icons = {
  // Bounded components - icons that may have a built in background such as a
  // circle behind them
  "alarm-clock": AlarmClock,
  "bell-snooze": BellSnooze,
  carbon: Carbon,
  clipboard: Clipboard,
  person: Person,
  pin: Pin,
  report: Report,
  settings: Settings,
  // Unbounded components - icons without a built in background
  // TODO: Add this in foundation.
  calendar: Calendar,
  // TODO: Add this in foundation.
  trashcan: Trashcan,
  // TODO: add this in foundation
  "circled-exclamation-point": CircledExclamationPoint,
  // TODO: add this in foundation
  "exclamation-in-triangle-filled": ExclamationInTriangleFilled,
  // TODO: add this in foundation
  "checkmark-in-circle-filled": CheckmarkInCircleFilled,
  check: Check,
  "chevron-down": ChevronDown,
  "chevron-up": ChevronUp,
  "chevron-up-down": ChevronUpDown,
  "chevron-left": ChevronLeft,
  "chevron-right": ChevronRight,
  close: Close,
  dashboard: Dashboard,
  document: Document,
  "down-triangle-small": DownTriangleSmall,
  "right-triangle-small": RightTriangleSmall,
  "enrolled-member": EnrolledMember,
  "eye-closed": EyeClosed,
  "eye-open": EyeOpen,
  failure: Failure,
  "file-dollar": FileDollar,
  group: Group,
  "group-of-three": GroupOfThree,
  image: Image,
  info: Info,
  "keyboard-return-key": KeyboardReturnKey,
  "left-arrow": LeftArrow,
  lock: Lock,
  members: Members,
  no: No,
  organization: Organization,
  people: People,
  search: Search,
  shot: Shot,
  "sign-out": SignOut,
  success: Success,
};

export type IconProps = {
  size: number;
  // disabled will take precedence over color by setting a default color
  // for disabled icons
  disabled?: boolean;
  color?: Color;
  clearBackground?: boolean;
};

export default function Icon({
  name,
  size,
  disabled,
  color,
  clearBackground,
}: IconProps & { name: IconName }) {
  // leaving this .toLowerCase although type checking should prevent a misuse
  // of capitals in the icon name, just to make sure nothing weird ever happens
  const IconComponent = icons[name.toLowerCase() as IconName];
  if (!IconComponent) {
    if (process.env.NODE_ENV !== "production")
      console.error(
        `Icon with name \`${name}\` not found. Check the icon name ` +
          `and make sure it is imported in \`icons/index.tsx\` and added to ` +
          `the \`icons\` object in the file`
      );
    return null;
  }
  return (
    <IconComponent
      size={size}
      disabled={disabled}
      color={color}
      clearBackground={clearBackground}
    />
  );
}
